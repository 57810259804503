import { BasicTemplateProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Template<BasicTemplateProps> = {
	schemaType: "template",
	displayName: "Basic Template",
	component: "BasicTemplate",
	type: { label: "Static", value: "static" },

	dimensions: [],

	content: [
		{
			type: "ComponentArray",
			title: "Hero Section",
			whiteList: ["BasicHero", "HeroCarousel", "HeroLanding", "HeroProgram"],
			key: "heroSection",
			contentType: "modules",
			maxItems: 1,
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"CypherCollection",
				"EventDistributor",
				"DownloadCollection",
				"EventDistributor",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"Files",
				"FullImageWithText",
				"FullVideo",
				"HighlightQuote",
				"ImageBanner",
				"LandingIntro",
				"LocationMap",
				"LogoCollection",
				"ModalLinkCollection",
				"NewsHighlightDistributor",
				"NewsSimpleDistributor",
				"OdsCollection",
				"OrderedCollection",
				"ProgramsDistributor",
				"QuoteTestimonial",
				"QuoteTestimonialCollection",
				"SocialShare",
				"TabsModules",
				"Wysiwyg",

				// TODO: Remove this line after QA module (this module is only for landings)
				"IntroForm",
			],
			key: "mainSection",
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "BasicTemplate",

		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},

		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/BasicTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/BasicTemplate/thumbnail@2x.png",
	},
};

export default schema;
